@charset "UTF-8";

/* @import url(./reset.css); */

/* == Border ============= */
.border {border: 1px solid #dee2e6;}
.border-0 {border: 0;}
.border-t {border-top: 1px solid #dee2e6;}
.border-te {border-top: 1px solid #dee2e6;border-right: 1px solid #dee2e6;}
.border-teb {border-top: 1px solid #dee2e6;border-right: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;}
.border-e {border-right: 1px solid #dee2e6;}
.border-eb {border-right: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;}
.border-ebs {border-right: 1px solid #dee2e6;border-bottom: 1px solid #dee2e6;border-left: 1px solid #dee2e6;}
.border-b {border-bottom: 1px solid #dee2e6;}
.border-bs {border-bottom: 1px solid #dee2e6;border-left: 1px solid #dee2e6;}
.border-bst {border-bottom: 1px solid #dee2e6;border-left: 1px solid #dee2e6;border-top: 1px solid #dee2e6;}
.border-s {border-left: 1px solid #dee2e6;}
.border-st {border-left: 1px solid #dee2e6;border-top: 1px solid #dee2e6;}
.border-ste {border-left: 1px solid #dee2e6;border-top: 1px solid #dee2e6;border-right: 1px solid #dee2e6;}
/* border-width */
.border-1 {border-width: 1px;}
.border-2 {border-width: 2px;}
.border-3 {border-width: 3px;}
.border-4 {border-width: 4px;}
.border-4 {border-width: 5px;}
/* rounded */
.rounded {border-radius: 0.25rem;}
.rounded-0 {border-radius: 0;}
.rounded-1 {border-radius: 0.2rem;}
.rounded-2 {border-radius: 0.25rem;}
.rounded-3 {border-radius: 0.3rem;}
.rounded-circle {border-radius: 50%;}
.rounded-pill {border-radius: 50rem;}
.rounded-top {border-top-left-radius: 0.25rem;border-top-right-radius: 0.25rem;}
.rounded-end {border-top-right-radius: 0.25rem;border-bottom-right-radius: 0.25rem;}
.rounded-bottom {border-bottom-right-radius: 0.25rem;border-bottom-left-radius: 0.25rem;}
.rounded-start {border-bottom-left-radius: 0.25rem;border-top-left-radius: 0.25rem;}


/* == Display ============= */
.d-none {display: none;}
.d-inline {display: inline;}
.d-inline-block {display: inline-block;}  
.d-block {display: block;}  
.d-grid {display: grid;}  
.d-table {display: table;}  
.d-table-row {display: table-row;}  
.d-table-cell {display: table-cell;}  
.d-flex {display: flex;}  
.d-inline-flex {display: inline-flex;}


/* == Flex ============= */
/* direction */
.flex-row {flex-direction: row;}  
.flex-column {flex-direction: column;}
/* justify content */
.justify-content-start {justify-content: flex-start;}  
.justify-content-end {justify-content: flex-end;}  
.justify-content-center {justify-content: center;}  
.justify-content-between {justify-content: space-between;}  
.justify-content-around {justify-content: space-around;}  
.justify-content-evenly {justify-content: space-evenly;}
/* align items */
.align-items-start {align-items: flex-start;}  
.align-items-end {align-items: flex-end;}  
.align-items-center {align-items: center;}  
.align-items-baseline {align-items: baseline;}  
.align-items-stretch {align-items: stretch;}
/* Fill */
.flex-fill{flex: 1 1 auto;}
/* wrap */
.flex-wrap {flex-wrap: wrap;}
.flex-nowrap {flex-wrap: nowrap;}


/* == overflow ============= */
.overflow-auto {overflow: auto;}  
.overflow-hidden {overflow: hidden;}  
.overflow-visible {overflow: visible;}  
.overflow-scroll {overflow: scroll;}


/* == position ============= */
.position-static {position: static;}  
.position-relative {position: relative;}  
.position-absolute {position: absolute;}  
.position-fixed {position: fixed;}  
.position-sticky {position: -webkit-sticky;position: sticky;}
/* inset */
.top-0 {top: 0;}  
.top-50 {top: 50%;}  
.top-100 {top: 100%;}  
.bottom-0 {bottom: 0;}  
.bottom-50 {bottom: 50%;}  
.bottom-100 {bottom: 100%;}  
.start-0 {left: 0;}  
.start-50 {left: 50%;}  
.start-100 {left: 100%;}  
.end-0 {right: 0;}  
.end-50 {right: 50%;}  
.end-100 {right: 100%;}
/* translate */
.translate-middle {transform: translate(-50%, -50%);}  
.translate-middle-x {transform: translateX(-50%);}  
.translate-middle-y {transform: translateY(-50%);}


/* == shadow ============= */
.shadow {box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);}
.shadow-sm {box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);}
.shadow-lg {box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);}


/* == Sizing ============= */
.w-25 {width: 25%;}  
.w-50 {width: 50%;}  
.w-75 {width: 75%;}  
.w-100 {width: 100%;}  
.w-auto {width: auto;}
.vw-100 {width: 100vw;}
.h-25 {height: 25%;}  
.h-50 {height: 50%;}  
.h-75 {height: 75%;}  
.h-100 {height: 100%;}  
.h-auto {height: auto;}
.vh-100 {height: 100vh;}


/* == Spacing ============= */
/* margin */
.m-0 {margin: 0;}
.m-1 {margin: 0.25rem;}
.m-2 {margin: 0.5rem;}
.m-3 {margin: 1rem;}
.m-4 {margin: 1.5rem;}
.m-5 {margin: 3rem;}
.m-auto {margin: auto;}
.mx-0 {margin-right: 0;    margin-left: 0;}
.mx-1 {margin-right: 0.25rem;    margin-left: 0.25rem;}
.mx-2 {margin-right: 0.5rem;    margin-left: 0.5rem;}
.mx-3 {margin-right: 1rem;    margin-left: 1rem;}
.mx-4 {margin-right: 1.5rem;    margin-left: 1.5rem;}
.mx-5 {margin-right: 3rem;    margin-left: 3rem;}
.mx-auto {margin-right: auto;    margin-left: auto;}
.my-0 {margin-top: 0;    margin-bottom: 0;}
.my-1 {margin-top: 0.25rem;    margin-bottom: 0.25rem;}
.my-2 {margin-top: 0.5rem;    margin-bottom: 0.5rem;}
.my-3 {margin-top: 1rem;    margin-bottom: 1rem;}
.my-4 {margin-top: 1.5rem;    margin-bottom: 1.5rem;}
.my-5 {margin-top: 3rem;    margin-bottom: 3rem;}
.my-auto {margin-top: auto;    margin-bottom: auto;}
.mt-0 {margin-top: 0;}
.mt-1 {margin-top: 0.25rem;}
.mt-2 {margin-top: 0.5rem;}
.mt-3 {margin-top: 1rem;}
.mt-4 {margin-top: 1.5rem;}
.mt-5 {margin-top: 3rem;}
.mt-auto {margin-top: auto;}
.me-0 {margin-right: 0;}
.me-1 {margin-right: 0.25rem;}
.me-2 {margin-right: 0.5rem;}
.me-3 {margin-right: 1rem;}
.me-4 {margin-right: 1.5rem;}
.me-5 {margin-right: 3rem;}
.me-auto {margin-right: auto;}
.mb-0 {margin-bottom: 0;}
.mb-1 {margin-bottom: 0.25rem;}
.mb-2 {margin-bottom: 0.5rem;}
.mb-3 {margin-bottom: 1rem;}
.mb-4 {margin-bottom: 1.5rem;}
.mb-5 {margin-bottom: 3rem;}
.mb-auto {margin-bottom: auto;}
.ms-0 {margin-left: 0;}
.ms-1 {margin-left: 0.25rem;}
.ms-2 {margin-left: 0.5rem;}
.ms-3 {margin-left: 1rem;}
.ms-4 {margin-left: 1.5rem;}
.ms-5 {margin-left: 3rem;}
.ms-auto {margin-left: auto;}
/* padding */
.p-0 {padding: 0;}
.p-1 {padding: 0.25rem;}
.p-2 {padding: 0.5rem;}
.p-3 {padding: 1rem;}
.p-4 {padding: 1.5rem;}
.p-5 {padding: 3rem;}
.px-0 {padding-right: 0;    padding-left: 0;}
.px-1 {padding-right: 0.25rem;    padding-left: 0.25rem;}
.px-2 {padding-right: 0.5rem;    padding-left: 0.5rem;}
.px-3 {padding-right: 1rem;    padding-left: 1rem;}
.px-4 {padding-right: 1.5rem;    padding-left: 1.5rem;}
.px-5 {padding-right: 3rem;    padding-left: 3rem;}
.py-0 {padding-top: 0;    padding-bottom: 0;}
.py-1 {padding-top: 0.25rem;    padding-bottom: 0.25rem;}
.py-2 {padding-top: 0.5rem;    padding-bottom: 0.5rem;}
.py-3 {padding-top: 1rem;    padding-bottom: 1rem;}
.py-4 {padding-top: 1.5rem;    padding-bottom: 1.5rem;}
.py-5 {padding-top: 3rem;    padding-bottom: 3rem;}
.pt-0 {padding-top: 0;}
.pt-1 {padding-top: 0.25rem;}
.pt-2 {padding-top: 0.5rem;}
.pt-3 {padding-top: 1rem;}
.pt-4 {padding-top: 1.5rem;}
.pt-5 {padding-top: 3rem;}
.pe-0 {padding-right: 0;}
.pe-1 {padding-right: 0.25rem;}
.pe-2 {padding-right: 0.5rem;}
.pe-3 {padding-right: 1rem;}
.pe-4 {padding-right: 1.5rem;}
.pe-5 {padding-right: 3rem;}
.pb-0 {padding-bottom: 0;}
.pb-1 {padding-bottom: 0.25rem;}
.pb-2 {padding-bottom: 0.5rem;}
.pb-3 {padding-bottom: 1rem;}
.pb-4 {padding-bottom: 1.5rem;}
.pb-5 {padding-bottom: 3rem;}
.ps-0 {padding-left: 0;}
.ps-1 {padding-left: 0.25rem;}
.ps-2 {padding-left: 0.5rem;}
.ps-3 {padding-left: 1rem;}
.ps-4 {padding-left: 1.5rem;}
.ps-5 {padding-left: 3rem;}


/* == text ============= */
/* Text alignment */
.text-start {text-align: left;}  
.text-end {text-align: right;}  
.text-center {text-align: center;}
/* Text wrapping and overflow */
.text-nowrap {white-space: nowrap;}
/* font-size */
.fs-1 {font-size: calc(1.375rem + 1.5vw);}
.fs-2 {font-size: calc(1.325rem + 0.9vw);}
.fs-3 {font-size: calc(1.3rem + 0.6vw);}
.fs-4 {font-size: calc(1.275rem + 0.3vw);}
.fs-5 {font-size: 1.25rem;}
.fs-6 {font-size: 1rem;}
.fs-7 {font-size: 0.75rem;}
.fs-8 {font-size: 0.5rem;}
.fs-9 {font-size: 0.25rem;}


/* Font weight */
.fw-bold {font-weight: 700;}
/* Line height */
.lh-1 {line-height: 1;}
.lh-sm {line-height: 1.25;}
.lh-base {line-height: 1.5;}
.lh-lg {line-height: 2;}
/* Text truncation */
.text-truncate {overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}


/* == Visibility ============= */
.visible {visibility: visible;}
.invisible {visibility: hidden;}


/* == Pointer ============= */
/* pointer event */
.pe-none {pointer-events: none;}
.pe-auto {pointer-events: auto;}
/* cursor */
.cursor-auto{cursor:auto;}
.cursor-default{cursor:default;}
.cursor-none{cursor:none;}
.cursor-pointer{cursor:pointer;}
.cursor-wait{cursor:wait;}
.cursor-text{cursor:text;}
.cursor-move{cursor:move;}
.cursor-not-allowed{cursor:not-allowed;}
.cursor-col-resize{cursor:col-resize;}
.cursor-zoom-in{cursor:zoom-in;}
.cursor-zoom-out{cursor:zoom-out;}


/* == object-fit ============= */
.object-cover{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/* object-position */
.object-t{object-position:top;}
.object-te{object-position:top right;}
.object-e{object-position:right;}
.object-be{object-position:bottom right;}
.object-b{object-position:bottom;}
.object-bs{object-position:bottom left;}
.object-s{object-position:left;}
.object-ts{object-position:top left;}