@charset "UTF-8";

/* 汎用class */
@import url(./template.css);

/* 変数 */
:root{
    --header-height: 50px;
    --footer-height: 64px;
    --roop-menu-height: 40%;
    --qr-flame-position: 50px;
}

/* リセット */
body{
    margin: 0;
}
ul{
    padding: 0;
}
li{
    list-style: none;
    text-decoration: none;
}
a {
    color: black;
    text-decoration: none;
}

/* ヘッダー */
#header {
    height: var(--header-height);
    background-color: #ffffff;
    z-index: 100;
}
#header .logo {
    height: 75%;
}


/* サイドメニュー */
#sideMenu{
    right: -120%;
    background-color: #ffffff;
    transition: all 0.6s;
    z-index: 400;
}
#sideMenu.active{
    right: 0;
}

#sideMenu ul {
    z-index: 400;
}

#sideMenu li{
    color: #333;
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: bold;
}
.sideMenuOpenbtn {
    position: fixed;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    z-index: 450;
}
.sideMenuOpenbtn span {
    display: inline-block;
    transition: all .4s;
    position: absolute;
    left: 14px;
    height: 3px;
    border-radius: 2px;
    background-color: #666;
    width: 45%;
}
.sideMenuOpenbtn span:nth-of-type(1) {
    top: 15px;
}
.sideMenuOpenbtn span:nth-of-type(2) {
    top: 23px;
}
.sideMenuOpenbtn span:nth-of-type(3) {
    top: 31px;
}
.sideMenuOpenbtn.active span:nth-of-type(1) {
    top: 18px;
    left: 18px;
    transform: translateY(6px) rotate(-45deg);
    width: 30%;
}
.sideMenuOpenbtn.active span:nth-of-type(2) {
    opacity: 0;
}
.sideMenuOpenbtn.active span:nth-of-type(3) {
    top: 30px;
    left: 18px;
    transform: translateY(-6px) rotate(45deg);
    width: 30%;
}

/* メイン */
#main{
    height: calc(100vh - ( var(--header-height) + var(--footer-height) ) );
}

/* メインビジュアル */
.mainVisualText {
    height: calc(100% - var(--roop-menu-height) );
    color: #fff;

}
/* スライドショー アニメーション */
.slideShowFadeIn{
    animation: slideShowFadeInAnime 2.0s forwards;
    opacity:0;
    z-index: 10;
}
@keyframes slideShowFadeInAnime{
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.slideShowFadeOut{
    animation: slideShowFadeOutAnime 2.0s forwards;
    opacity:1;
}
@keyframes slideShowFadeOutAnime{
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* 機能一覧 */
#loopMenu{
    height: var(--roop-menu-height);
    background-color:rgba(247, 247, 247, 0.4);
    z-index: 50;
}
#loopMenu .card-box {
    width: calc(100% / 3);
}
#loopMenu .card-box .cardbtn {
    border: #1767AE 1px solid;
    width:100%;
    height: 10vh;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.85rem;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
}


/* 機能 中身 */
#lowerMenu{
    position: fixed;
    z-index: 200;
    left: 0;
    bottom: -120%;
    width: 100%;
    height: 100vh;
    background: #fff;
}
#lowerMenu.active {
    bottom: 0;
}
#lowerMenu .header{
    height: var(--header-height);
}
#lowerMenu .closeBtn{
    width: 30px;
    padding-top: 3px;
}

/* フッター */
#footer {
    height: var(--footer-height);
    background-color: white;
    z-index: 300;
}
#footer .footerContent {
    width: calc(100%/6);
}
#footer .footerContent .imgBox {
    height: 30px;
    width: 30px;
}
#footer .footerContentCenter {
    width: calc(100%/6*2);
}
.QRScan {
    z-index: 100;
}
.QRScan .flamePart.side{
    width: var(--qr-flame-position);
    background-color: #898989b3;
}
.QRScan .flamePart.center{
    width: calc(100% - (var(--qr-flame-position)*2) );
}
.QRScan .flamePart.center .top{
    width: 100%;
    height: var(--qr-flame-position);
    background-color: #898989b3;
}
.QRScan .flamePart.center .center{
    width: 100%;
    aspect-ratio: 1;
}
.QRScan .flamePart.center .end{
    width: 100%;
    height: auto;
    background-color: #898989b3;
}
.QRScan .text{
    bottom: 25%;
    left: 0;
    text-shadow: 0px 0px 6px white, 0px 0px 6px white;
}
#QRcodeOn {
    transform: translate(-50%, 10%);
    aspect-ratio: 1/1;
    background-color: white;
    border: 1px solid #1767AE;
}
#QRcodeOn img {
    width: 50%;
    position: absolute;
    transform: translate(-50%,50%);
}